import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Spin } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import AddressForm from '../components/AddressForm';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class LicenceAddress extends React.Component{

    state={
        datarequested:true,
        address_obj:{}
    }


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }
      this.setState({datarequested:false})

      this.compo_nent_loade()

    }

    //component loaded
    compo_nent_loade=()=>{
      this.setState({address_obj:this.props.licence_record.licence_address?JSON.parse(this.props.licence_record.licence_address):{}})

    }

    

    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>            </div>
        )
  
      }else{
        return(
          <div>
            <Card>

              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
              
              {/**Code */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Name:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.address_obj.address_name}</span>
              </span>
              

              {/**Jurisdiction */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Line 1:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.address_obj.Line1}</span>
              </span>

              {/**Application date */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Line 2:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.address_obj.Line2}</span>
              </span>

                {/**Name */}
                <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Line 3:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.address_obj.Line3}</span>
              </span>

              {/**Type */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Country:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.address_obj.country}</span>
              </span>

              
              </div>
              <Divider></Divider>
              <AddressForm 
                button_command={"Update Address"}
                created_licence={this.props.licence_record.id}
                on__finish={this.compo_nent_loade}
                licence_id={this.props.licence_record.id}
              />

            </Card>
          </div>
      )
      }
       
    }
}

export default LicenceAddress; 