import React, { useState, useRef, useEffect } from 'react';
import { Map, TileLayer, FeatureGroup, GeoJSON } from 'react-leaflet';
import { Popup } from 'react-leaflet';

import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import proj4 from 'proj4';
import L from 'leaflet';
import { Form, Input, Button,Spin,Select,DatePicker, Divider, Card, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;

const ShapesMap = (props) => {
    const [shapes, setShapes] = useState([]);

    // Helper function to convert WKT to GeoJSON
    const parseGeometry = (wkt) => {
        // Remove SRID=XXXX; prefix if present
        const wktWithoutSrid = wkt.replace(/SRID=\d+;/, '');
    
        let coordinates = [];
    
        if (wktWithoutSrid.startsWith('POLYGON')) {
            // Handle POLYGON geometry
            coordinates = wktWithoutSrid
                .replace('POLYGON ((', '')
                .replace('))', '')
                .split(', ')
                .map(pair => pair.split(' ').map(Number)); // Convert to number
    
            return {
                type: 'Polygon',
                coordinates: [coordinates] // GeoJSON expects a nested array for polygons
            };
        } else if (wktWithoutSrid.startsWith('LINESTRING')) {
            // Handle LINESTRING geometry (for polylines)
            coordinates = wktWithoutSrid
                .replace('LINESTRING (', '')
                .replace(')', '')
                .split(', ')
                .map(pair => pair.split(' ').map(Number)); // Convert to number
    
            return {
                type: 'LineString',
                coordinates: coordinates // GeoJSON format for LineString
            };
        }
    
        throw new Error('Unsupported WKT geometry type');
    };
    

     // Function to bind popups with shape information
     const onEachFeature = (feature, layer) => {
        if (feature.properties && feature.properties.legal_description) {
            layer.bindPopup(`
                <strong>Legal Description:</strong> ${feature.properties.legal_description}<br />
                <strong>SRID:</strong> ${feature.properties.srid}<br />
                <strong>Area:</strong> ${feature.properties.calculated_area} hectares<br />
                <strong>Length:</strong> ${feature.properties.length} meters<br />
            `);
        }
    };


    // Fetch shapes for the licence
    useEffect(() => {
        axios.get(serverconfig.backendserverurl+`/customqueries/licence_shapes/${props.licence_id}/`)
            .then(response => {

                //setShapes(response.data);
                console.log('API Response:', JSON.stringify(response.data)); // Debugging
                // Ensure data is an array; if it's a FeatureCollection, extract the features array
                const features = response.data.features.map(feature => ({
                    ...feature,
                    geometry: parseGeometry(feature.geometry) // Convert WKT to GeoJSON
                }));
                setShapes(features);


            })
            .catch(error => {
                console.error('Error fetching shapes', error);
            });

        }, [props.licence_id]);

    return (
        <Map center={[1.3733, 32.2903]} zoom={7} style={{ height: '500px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; OpenStreetMap contributors"
            />
           {shapes.length > 0 && (
                <GeoJSON
                    data={{ type: 'FeatureCollection', features: shapes }}
                    onEachFeature={onEachFeature}
                />
            )}
        </Map>
    );

};

export default ShapesMap;
