import React from 'react';
import { Form, Input, Button,Spin,Select,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;



const { Option } = Select;

class AddressForm extends React.Component {

  state = {
    datarequested:true,
    Line1:'',
    address_name:'',
    Line2:'',
    Line3:'',
    countries:[],
    country:''

  }

  componentDidMount(){   
    this.component_reloaded()
  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   }else{
      username=''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   // this.setState({datarequested:false})


    axios.get(serverconfig.backendserverurl+`/api/countries/`)
    .then(res => {
        this.setState({
            countries:res.data
        })
  
        this.setState({datarequested:false})
  
    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();

    let data={
      "Line2":this.state.Line2,
      "Line1":this.state.Line1,
      "Line3":this.state.Line3,
      "address_name":this.state.address_name,
      "country":this.state.country,

    }
    form_data.append('address_data', JSON.stringify(data));
    form_data.append('licence_id', this.props.licence_id);

    axios.post(serverconfig.backendserverurl+'/customqueries/createlicenceAddress', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    //this.component_reloaded()

    ///refesrh
    this.props.on__finish()

  }    

  )
  .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
      <h4></h4>

    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
     

      {/**Next button */}
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
       <h4 style={{display:'flex',justifyContent:'center',color:'green'}}>Address Form</h4>
         <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.button_command}</Button>
      </FormItem>

      </div>  
      <Divider></Divider>


      <FormItem label="Address Name">
        <TextArea
            placeholder="Address Name"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.address_name}
            onChange={(val)=>{this.setState({address_name:val.target.value})}}
          />
        
      </FormItem>


      <FormItem label="Line1"
        name="Line1"
        rules={[
          {
            required: false,
            message: 'Please input Line1',
          },
        ]}
      >
        <Input name="Line1"  
         placeholder="Line1"
         value={this.state.Line1} 
         onChange={(val)=>{this.setState({Line1:val.target.value})}} />
      </FormItem>



      <FormItem label="Line2"
        name="Line2"
        rules={[
          {
            required: false,
            message: 'Please input Line2',
          },
        ]}
      >
        <Input name="Line2"  
         placeholder="Line2"
         value={this.state.Line2} 
         onChange={(val)=>{this.setState({Line2:val.target.value})}} />
      </FormItem>


      <FormItem label="Line3"
        name="Line3"
        rules={[
          {
            required: false,
            message: 'Please input Line3',
          },
        ]}
      >
        <Input name="Line3"  
         placeholder="Line3"
         value={this.state.Line3} 
         onChange={(val)=>{this.setState({Line3:val.target.value})}} />
      </FormItem>


      <FormItem label="Country"
        name="country"
        rules={[
          {
            required: true,
            message: 'Please select',
          },
        ]}
      >
          <Select 
          placeholder="Type" 
          style={{  }} 
          value={this.state.country}
          onChange={(val)=>{
            this.setState({country:val})
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}   
          >
            {this.state.countries.map(
              (cntry)=>(
                <Option value={cntry.name}>{cntry.name}</Option>
              ))}
          </Select>
      </FormItem>



       </Form>
        </div>
      );
    }

  }

}


export default AddressForm;



