import React, { useState, useRef, useEffect } from 'react';
import { Map, TileLayer, FeatureGroup, GeoJSON } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import proj4 from 'proj4';
import L from 'leaflet';
import { Form, message, Card } from 'antd';
import axios from 'axios';
import * as reactstrp from 'reactstrap';
import 'leaflet-geometryutil';
import * as serverconfig from '../serverconn'


var CryptoJS = require("crypto-js");

const FormItem = Form.Item;

// Define Uganda's boundary GeoJSON (Replace with actual boundary data)
const ugandaBoundary = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [29.605, -1.479],
                        [29.605, 4.501],
                        [35.001, 4.501],
                        [35.001, -1.479],
                        [29.605, -1.479]
                    ]
                ]
            },
            "properties": {}
        }
    ]
};

const coordinateSystems = [
    { name: 'WGS 84', srid: 4326 },
    { name: 'Web Mercator', srid: 3857 }
];

const ShapeDrawForm = (props) => {
    const [selectedSrid, setSelectedSrid] = useState(4326); // Default SRID
    const [shapes, setShapes] = useState([]);
    const [coordinates, setCoordinates] = useState([]); // State to hold the coordinates
    const mapRef = useRef(); // Create a ref for the map

    const [area, setArea] = useState(0);
    const [length, setLength] = useState(0);

    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current.leafletElement;
            const boundaryLayer = L.geoJSON(ugandaBoundary);
            boundaryLayer.addTo(map);
        }
    }, []);

    const handleSridChange = (event) => {
        setSelectedSrid(parseInt(event.target.value, 10));
    };

    const onCreated = (e) => {
        const { layerType, layer } = e;
        let calLength = 0;
        let calArea = 0;

        // Handle polygons for area calculation
        if (layerType === 'polygon') {
            const latlngs = layer.getLatLngs();
            const calculatedArea = L.GeometryUtil.geodesicArea(latlngs[0]);
            const areaInHectares = (calculatedArea / 10000).toFixed(2); // Convert m² to hectares
            setArea(areaInHectares); // Set area
            setLength(0); // Reset length
            calArea += areaInHectares;
        }

        // Handle polylines for length calculation
        if (layerType === 'polyline') {
            const latlngs = layer.getLatLngs();
            const totalLength = L.GeometryUtil.length(latlngs).toFixed(2); // Length in meters
            setLength(totalLength); // Set length
            setArea(0); // Reset area
            calLength += totalLength;
        }

        const geojson = layer.toGeoJSON();

        // Check if shape is within Uganda's boundaries
        if (!isShapeInsideBoundary(layer)) {
            alert('Shape is outside Uganda boundaries');
            return;
        }

        const geometryType = geojson.geometry.type;
        let transformedCoordinates;

        switch (geometryType) {
            case 'Point':
                transformedCoordinates = proj4(`EPSG:4326`, `EPSG:${selectedSrid}`, geojson.geometry.coordinates);
                break;
            case 'LineString':
            case 'MultiPoint':
                transformedCoordinates = geojson.geometry.coordinates.map(coord =>
                    proj4(`EPSG:4326`, `EPSG:${selectedSrid}`, coord)
                );
                break;
            case 'Polygon':
            case 'MultiLineString':
                transformedCoordinates = geojson.geometry.coordinates.map(ring =>
                    ring.map(coord => proj4(`EPSG:4326`, `EPSG:${selectedSrid}`, coord))
                );
                break;
            case 'MultiPolygon': // Handle multi-part polygons
                transformedCoordinates = geojson.geometry.coordinates.map(polygon =>
                    polygon.map(ring =>
                        ring.map(coord => proj4(`EPSG:4326`, `EPSG:${selectedSrid}`, coord))
                    )
                );
                break;
            default:
                alert('Unsupported geometry type');
                return;
        }

        // Construct the transformed GeoJSON
        const transformedGeom = {
            ...geojson,
            geometry: {
                ...geojson.geometry,
                coordinates: transformedCoordinates
            }
        };

        const shapeData = {
            id: shapes.length + 1,
            name: 'New Shape',
            geom: transformedGeom.geometry, // Store the transformed geometry
            srid: selectedSrid
        };

        setShapes([...shapes, shapeData]);

        // Extract and display coordinates
        setCoordinates(transformedGeom.geometry.coordinates);

        // CREATE API REQUEST
        let username = '';
        if (localStorage.getItem("username")) {
            username = CryptoJS.AES.decrypt(localStorage.getItem("username"), 'my-secret-key@123').toString(CryptoJS.enc.Utf8);
        }

        let form_data = new FormData();
        form_data.append('srid', selectedSrid);
        form_data.append('geom', JSON.stringify(transformedGeom.geometry));
        form_data.append('name', "New Shape");
        form_data.append('licence_id', props.licence_id);
        form_data.append('username', username);
        form_data.append('area', calArea);
        form_data.append('length', calLength);

        axios.post(serverconfig.backendserverurl+'/customqueries/create_shapefile', form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then(res => {
            message.info(res.data.message);
        })
        .catch(error => console.log(error));
    };

    const isShapeInsideBoundary = (shape) => {
        const map = mapRef.current.leafletElement;
        const boundaryLayer = L.geoJSON(ugandaBoundary).getLayers()[0];
        return boundaryLayer.getBounds().contains(shape.getBounds().getCenter());
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Map center={[1.3733, 32.2903]} zoom={7} style={{ height: '500px', width: '70%' }} ref={mapRef}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; OpenStreetMap contributors"
                />
                <FeatureGroup>
                    <EditControl
                        position='topleft'
                        onCreated={onCreated}
                        onEdited={(e) => console.log(e)}
                        onDeleted={(e) => console.log(e)}
                        draw={{
                            rectangle: false,
                            polyline: true,
                            polygon: true,
                            circle: false,
                            marker: false,
                            circlemarker: false,
                        }}
                    />

                    {shapes.map(shape => (
                        <GeoJSON key={shape.id} data={shape.geom} />
                    ))}

                    {/* Add Uganda boundary to the map */}
                    <GeoJSON data={ugandaBoundary} />
                </FeatureGroup>
            </Map>

            <div style={{ height: '500px', width: '30%', marginLeft: 5 }}>
                <Card>
                    <FormItem label="Coordinate System">
                        <select onChange={handleSridChange} value={selectedSrid}>
                            {coordinateSystems.map(({ name, srid }) => (
                                <option key={srid} value={srid}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </FormItem>

                    {area > 0 && (
                        <div>
                            <h6>Area of drawn shape: {area} hectares</h6>
                        </div>
                    )}

                    {length > 0 && (
                        <div>
                            <h6>Length of drawn shape: {length} meters</h6>
                        </div>
                    )}

                    <h6>Coordinates:</h6>

                    {coordinates.length > 0 ? (
                        <div style={{ display: 'flex', overflowX: 'auto' }}>
                            <reactstrp.Table bordered>
                                <thead>
                                    <tr>
                                        <th>Latitude</th>
                                        <th>Longitude</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {coordinates.flat().map((item, index) => (
                                        <tr key={index}>
                                            <td>{item[1]}</td>
                                            <td>{item[0]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </reactstrp.Table>
                        </div>
                    ) : (
                        <div>No coordinates available</div>
                    )}
                </Card>
            </div>
        </div>
    );
};

export default ShapeDrawForm;
