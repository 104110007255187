import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
//import 'antd/dist/antd.css'; 
import 'antd/dist/result.css';

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'

import LoginLayout from './views/containers/LoginLayout'
import Login from './views/containers/Login';
import SaccoLayout from './views/containers/Saccoslayout'
import Breadcrumbs from './Breadcrumbs';
//All components
import MineralLicenceList from './views/containers/MineralLicenceCategoriesList';
import AccountList from './views/containers/AccountsListView'
import AccountDetail from './views/containers/AccountDetailView'

import DocumentTypeList from './views/containers/DocumentTypes';
import AgreementTypeList from './views/containers/AgreementTypes';
import LicenceCommodities_All from './views/containers/LicenceCommodities_All';
import Jurisdictions from './views/containers/Jurisdictions';
import LicencesTab from './views/containers/LicencesTab';
import DashBoard from './views/containers/DashBoard';
import Parties from './views/containers/Parties';
import OtherSettings from './views/containers/OtherSettings';
import Licence_DTabs from './views/containers/Licence_DTabs';
// Containers

const App = (props) => {

  const [all_routes, setall_routes] = useState(
    [
      {
        key:1,
        name:"Home",
        Path:"/",
        component:DashBoard

      },
      {
        key:2,
        name:"Users",
        Path:"/useraccounts",
        component:AccountList
      },
      {
        key:3,
        name:"User Details",
        Path:"/useraccounts/:accountID",
        component:AccountDetail
      },
      {
        key:4,
        name:"Mineral Licence Categories",
        Path:"/mineral_rights_categories",
        component:MineralLicenceList
      },

      {
        key:4,
        name:"Document Types",
        Path:"/document_types",
        component:DocumentTypeList
      },

      {
        key:5,
        name:"Agreement Types",
        Path:"/agreement_types",
        component:AgreementTypeList
      },

      {
        key:6,
        name:"Commodities",
        Path:"/commodities",
        component:LicenceCommodities_All
      },
      
      {
        key:7,
        name:"Jurisdictions",
        Path:"/jurisdictions",
        component:Jurisdictions
      },

      {
        key:8,
        name:"Licences",
        Path:"/licences",
        component:LicencesTab
      },

      {
        key:9,
        name:"Parties",
        Path:"/parties",
        component:Parties
      },
      {
        key:10,
        name:"Other Settings",
        Path:"/othersettings",
        component:OtherSettings
      },
      {
        key:11,
        name:"Licence Details",
        Path:"/licence_details/:licenceID",
        component:Licence_DTabs
      },
      
    ]

  );


  useEffect(() => {
    props.onTryAutoSignup();

  }, []);


    return (
      props.isAuthenticated ?
      <Router>
        <SaccoLayout {...props}>

       {/* <Breadcrumbs all_routes={all_routes} />*/}

        <Switch>

          {/* Dynamic restaurant routes */}
          {all_routes.map((route, index) => (
            <Route 
              key={index}
              path={route.Path}
              exact // Ensure exact matching
              component={route.component} // Directly use component prop
              >
            
            </Route>
          ))}

        </Switch>

        </SaccoLayout>
      </Router>
      :
      <Router>
      <LoginLayout>
        <Login />
      </LoginLayout>
      </Router>
    );
  }


const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
