import React from 'react';
import { Form, Input, Button,Spin,Select, message,Popover,Switch,Popconfirm,Card,Divider,Table } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DeleteOutlined,PlusCircleFilled,PlusCircleOutlined,PrinterOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import ReactToPrint from "react-to-print";
import CurrencyFormat from "react-currency-format";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var userother_email=''

const { Option } = Select;

class LicencePeopleAttachmentForm extends React.Component {

  state = {
    datarequested:true,
    licence_id:this.props.licence_id,
    receivers_list:[],

    personal_parties: [],
    selected_list:[],


  }


  componentDidMount(){   

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/personal_parties/`)
    .then(res => {
        this.setState({
          personal_parties:res.data
        })

        this.setState({datarequested:false})
    })


  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.selected_list<=0){
      message.error("list empty")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('selected_list',JSON.stringify(this.state.selected_list));
      form_data.append('licence_id',this.state.licence_id);

      axios.post(serverconfig.backendserverurl+'/customqueries/attach_people_tolicence', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
      this.setState({datarequested:false})
      message.info(res.data.message)

      //refesh parent
      this.props.onRefresh()
  
      })
      .catch(error => console.log(error))

    }

  }


   

    //chekc if invoice exists
    iscomm_added=(account, inv_list) => {
      return inv_list.some((item) => item === account);
    };

  

  render(){

    const columns = [
      {
        title: "Add To List",
        dataIndex: "code",
        key: "id",
        render: (text,record) =>
        <p>
          <PlusCircleFilled 
          style={{color:this.iscomm_added(record.code,this.state.selected_list)===true?"green":'grey',
          fontSize: '30px'
          ,margin:2}}
          
          onClick={()=>{
            if (this.iscomm_added(record.code,this.state.selected_list)===true){
             
              this.setState({ selected_list:  [...this.state.selected_list.filter(todo => todo
                !==record.code)]});
            
              console.log("person removed")
          
            }else{
              this.setState({selected_list: [...this.state.selected_list,record.code]});
              console.log("person added")
            }

          }}
      
          />
        </p>
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'id',
        ...this.getColumnSearchProps('first_name'),
      },

      {
        title: 'Second Name',
        dataIndex: 'second_name',
        key: 'id',
        ...this.getColumnSearchProps('second_name'),
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'id',
      },
      {
        title: 'ID Number',
        dataIndex: 'id_number',
        key: 'id',
      },
      {
        title: 'Date Of Birth',
        dataIndex: 'date_of_birth',
        key: 'id',
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'id',
      },
      {
        title: 'Phone',
        dataIndex: 'phone_one',
        key: 'id',
      },
     

    ];



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return (
        <div>
          <Card>

          {/**Next button */}
          <h6>Select People</h6>
          <Divider></Divider>

          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <h4 style={{display:'flex',justifyContent:'center',color:'green'}}>Licence People Attachment Form</h4>
      
          <FormItem>
          <Button 
           type="primary"
           htmlType="submit"
           onClick={(event) => this.handleFormSubmit(event)}

           >{this.props.button_command} </Button>
          </FormItem>
          </div>  
          <Divider></Divider>


         {
          this.state.personal_parties.length>0?
          <Table
          columns={columns}
          pagination={{ showQuickJumper: true, showSizeChanger: true }}
          dataSource={this.state.personal_parties}
          scroll={{ x: 1000 }}
          bordered
          size='small'
        />

        :
        null
        }

       </Card>
        </div>
      );
    }

  }

}


export default LicencePeopleAttachmentForm;



