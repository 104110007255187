import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var userlicence=''
var username=''
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;



const { Option } = Select;

class MineralLicenceCategoryUpdateForm extends React.Component {

  state = {
    licence:'' ,  
    purpose:'',
    datarequested:true,
    period:'',
    remarks:''

  }

  componentDidMount(){   
    this.component_reloaded()
  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    const category_ID=this.props.category_ID;
    axios.get(`${serverconfig.backendserverurl}/api/mineral_licence_categories/${category_ID}`)
    .then(res => {  

        this.setState({licence:res.data.licence})
        this.setState({purpose:res.data.purpose})
        this.setState({period:res.data.period})
        this.setState({remarks:res.data.remarks})

        this.setState({datarequested:false})
    })




  }



  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return (
        <div>
    <Form
        
    >
      <FormItem label="licence"
       
      >
        <Input licence="licence"  placeholder="licence." value={this.state.licence} onChange={(val)=>{this.setState({licence:val.target.value})}} />
      </FormItem>


      <FormItem label="Purpose">
        <TextArea
            placeholder="Purpose"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.purpose}
            onChange={(val)=>{this.setState({purpose:val.target.value})}}
          />
        
      </FormItem>


      <FormItem label="Period">
            <Input
            placeholder="Period"
            value={this.state.period}
            onChange={(val)=>{this.setState({period:val.target.value})}}
          />
        
      </FormItem>


      <FormItem label="Remarks">
        <TextArea
            placeholder="Remarks"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.remarks}
            onChange={(val)=>{this.setState({remarks:val.target.value})}}
          />
        
      </FormItem>
     

      <FormItem>
        <Button  type="primary" htmlType="button" 
            onClick={()=>{

            var category_ID=this.props.category_ID
            this.setState({datarequested:true})

            let form_data = new FormData();
            form_data.append('licence', this.state.licence);
            form_data.append('purpose', this.state.purpose);
            form_data.append('period', this.state.period);
            form_data.append('remarks', this.state.remarks);

            axios.put(serverconfig.backendserverurl+`/api/mineral_licence_categories/${category_ID}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
            .then(res => {
            this.setState({datarequested:false})
            this.component_reloaded()

            ///refesrh
            this.props.onRefresh()

            }    

            )
            .catch(error => console.log(error))


        }}>Update Licence Category</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default MineralLicenceCategoryUpdateForm;



