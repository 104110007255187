import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import Licence_Persons_list from './Licence_Persons_list';
import Licence_Company_list from './Licence_Company_list';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;

var token= ''
var bizuserid= ''


class LicenceParties_list extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    licence_person_attachment: [],
    datarequested:true,
    licence_company_attachment:[]

  };

  componentDidMount(){
    this.comp_reloaded()
    
}

comp_reloaded=()=>{

  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
 }else{
    bizuserid= ''
 }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/api/licence_person_attachment/?licence=${this.props.licence_id}`)
  .then(res => {
      this.setState({
          licence_person_attachment:res.data
      })

     // this.setState({datarequested:false})

  })


  axios.get(serverconfig.backendserverurl+`/api/licence_company_attachment/?licence=${this.props.licence_id}`)
  .then(res => {
      this.setState({
        licence_company_attachment:res.data
      })

      this.setState({datarequested:false})

  })


  

}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
   
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>
          <Tabs
            defaultActiveKey="1"
            onChange={() => {}}
            tabPosition={'top'}
          >
             <TabPane tab={"People ( "+ this.state.licence_person_attachment.length+" )"} key="1">
              <Licence_Persons_list
                licence_id={
                  this.props.licence_id
                }
              
              />
       
             </TabPane>
             

             <TabPane tab={"Companies ( "+ this.state.licence_company_attachment.length+" )"} key="2">
              <Licence_Company_list 
                  licence_id={
                    this.props.licence_id
                  }
               />
              
             </TabPane>
             
             </Tabs>
            
        </div>
    )

    }

   
  }
}

export default LicenceParties_list; 
