import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import ShapeDrawForm from '../components/ShapeDrawForm';
import Shapes_Tabs from './Shapes_Tabs';
import LicenceDetails from './LicenceDetails';
import LicenceCommoditiesForm from '../components/LicenceCommoditiesForm';
import CommoditiesTab from './CommoditiesTab'
import LicenceAddress from './LicenceAddress';
import PartiesTab from './PartiesTab';


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;


var token= ''
var bizuserid= ''


class Licence_DTabs extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    licence_obj: {},
    datarequested:true,

  };

  componentDidMount(){
    this.comp_reloaded()
    
}

comp_reloaded=()=>{

  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
 }else{
    bizuserid= ''
 }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/customqueries/licence_details/${this.props.match.params.licenceID}`)
  .then(res => {
      this.setState({
        licence_obj:res.data.data
      })
      this.setState({datarequested:false})

  })

  console.log("licence_id: "+this.props.match.params.licenceID)

}


  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>

          <Tabs
            defaultActiveKey="1"
            onChange={() => {}}
            tabPosition={'top'}
            >

            <TabPane tab="Details" key="1">
              <LicenceDetails  licence_record={this.state.licence_obj} />
            </TabPane>

            <TabPane tab="Address" key="8">
              <LicenceAddress  licence_record={this.state.licence_obj} />
            </TabPane>

            <TabPane tab={"Commodities ( "+this.state.licence_obj.commodites_no+" )"} key="7">
              <CommoditiesTab
                licence_id={this.props.match.params.licenceID}
              />
            </TabPane>

            <TabPane tab={"Shapes ( "+this.state.licence_obj.shapes_no+" )"} key="2">
               <Shapes_Tabs  licence_id={this.props.match.params.licenceID} />
            </TabPane>

            
            <TabPane tab={"Parties ( "+this.state.licence_obj.parties_no+" )"} key="3">
              <PartiesTab 
               licence_id={this.props.match.params.licenceID}
              />
              

            </TabPane>

            <TabPane tab="Documents" key="4">

            </TabPane>


            <TabPane tab="Agreements" key="6">

            </TabPane>

             </Tabs>

        </div>
    )

    }

   
  }
}

export default Licence_DTabs; 
