// Breadcrumbs.js
import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';

const Breadcrumbs = ({ location, all_routes }) => {

  const getBreadcrumbName = (path) => {
    const route = all_routes.find(route => matchPath(location.pathname, { path: route.Path, exact: true, strict: false }));
    return route ? route.name : '';
  };

  const breadcrumbItems = all_routes.map(route => {
    const match = matchPath(location.pathname, { path: route.Path, exact: true, strict: false });
    if (match) {
      return (
        <Breadcrumb.Item key={route.Path}>
          <Link to={route.Path}>{getBreadcrumbName(route.Path)}</Link>
        </Breadcrumb.Item>
      );
    }
    return null;
  }).filter(item => item !== null);

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      {breadcrumbItems}
    </Breadcrumb>
  );
};

export default withRouter((props) => <Breadcrumbs {...props} />);
