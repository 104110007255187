import React from 'react';
import { Form, Input, Button,Spin,Select, message,Popover,Switch,Popconfirm,Card,Divider,Table } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DeleteOutlined,PlusCircleFilled,PlusCircleOutlined,PrinterOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import ReactToPrint from "react-to-print";
import CurrencyFormat from "react-currency-format";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var userother_email=''

const { Option } = Select;

class LicenceCommoditiesForm extends React.Component {

  state = {
    datarequested:true,
    licence_id:this.props.licence_id,
    receivers_list:[],

    licence_commodities: [],

    selected_list:[],


  }


  componentDidMount(){   

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/licence_commodities/`)
    .then(res => {
        this.setState({
          licence_commodities:res.data
        })

        this.setState({datarequested:false})
    })


  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.selected_list<=0){
      message.error("Invoices' list empty")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('selected_list',JSON.stringify(this.state.selected_list));
      form_data.append('licence_id',this.state.licence_id);

      axios.post(serverconfig.backendserverurl+'/customqueries/createlicence_commodities', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
      this.setState({datarequested:false})
      message.info(res.data.message)

      //refesh parent
      this.props.onRefresh()
  
      })
      .catch(error => console.log(error))

    }

  }


   

    //chekc if invoice exists
    iscomm_added=(account, inv_list) => {
      return inv_list.some((item) => item === account);
    };

  

  render(){

    const columns = [
      {
        title: "Add To List",
        dataIndex: "code",
        key: "id",
        render: (text,record) =>
        <p>
          <PlusCircleFilled 
          style={{color:this.iscomm_added(record.code,this.state.selected_list)===true?"green":'grey',
          fontSize: '30px'
          ,margin:2}}
          
          onClick={()=>{
            if (this.iscomm_added(record.code,this.state.selected_list)===true){
             
              this.setState({ selected_list:  [...this.state.selected_list.filter(todo => todo
                !==record.code)]});
            
              console.log("commodity removed")
          
            }else{
              this.setState({selected_list: [...this.state.selected_list,record.code]});
              console.log("commodity added")
            }

          }}
      
          />
        </p>
      },
      {
        title: "Commodity",
        dataIndex: "name",
        key: "id",
      },
      {
        title: "Group",
        dataIndex: "commodity_groupname",
        key: "id",
      },
      {
        title: "Details",
        dataIndex: "description",
        key: "id",
      },
     

    ];



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return (
        <div>
          <Card>

          {/**Next button */}
          <h6>Select Commodities</h6>
          <Divider></Divider>


          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <h4 style={{display:'flex',justifyContent:'center',color:'green'}}>Commodities Form</h4>
      
          <FormItem>
          <Button 
           type="primary"
           htmlType="submit"
           onClick={(event) => this.handleFormSubmit(event)}

           >{this.props.button_command} </Button>
          </FormItem>
          </div>  
          <Divider></Divider>


         {
          this.state.licence_commodities.length>0?
          <Table
          columns={columns}
          pagination={{ showQuickJumper: true, showSizeChanger: true }}
          dataSource={this.state.licence_commodities}
          scroll={{ x: 1000 }}
          bordered
          size='small'
        />

        :
        null
        }

       </Card>
        </div>
      );
    }

  }

}


export default LicenceCommoditiesForm;



