import React from 'react';
import { Form, Input, Button,Spin,Select,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;



const { Option } = Select;

class PersonUpdateForm extends React.Component {

  state = {
    first_name:'' ,  
    datarequested:true,
    period:'',
    comments:'',
    second_name:'',
    id_number:'',
    gender:'',
    date_of_birth:'',
    date_ofregistration:'',
    phone_one:'',
    phone_two:'',

    box_number:'',
    website:'',
    email:'',
    profession:'',
    nationality:'',
    countries:[],
    marital_status:''

  }

  componentDidMount(){   
    this.component_reloaded()
  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   }else{
      username=''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    var person_ID=this.props.person_ID
    axios.get(`${serverconfig.backendserverurl}/api/personal_parties/${person_ID}`)
    .then(res => {  

        this.setState({first_name:res.data.first_name})
        this.setState({second_name:res.data.second_name})

        this.setState({id_number:res.data.id_number})
        this.setState({gender:res.data.gender})
        this.setState({date_of_birth:res.data.date_of_birth})
        this.setState({date_ofregistration:res.data.date_ofregistration})
        this.setState({phone_one:res.data.phone_one})
        this.setState({phone_two:res.data.phone_two})
        this.setState({email:res.data.email})
        this.setState({website:res.data.website})
        this.setState({box_number:res.data.box_number})
        this.setState({profession:res.data.profession})
        this.setState({nationality:res.data.nationality})
        this.setState({marital_status:res.data.marital_status})
        this.setState({comments:res.data.comments})

    })



    axios.get(serverconfig.backendserverurl+`/api/countries/`)
    .then(res => {
        this.setState({
            countries:res.data
        })
  
        this.setState({datarequested:false})
    
    })





  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    var person_ID=this.props.person_ID


    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('first_name', this.state.first_name);
    form_data.append('second_name', this.state.second_name);
    form_data.append('id_number', this.state.id_number);
    form_data.append('gender', this.state.gender);
    form_data.append('date_of_birth', this.state.date_of_birth);
    form_data.append('date_ofregistration', this.state.date_ofregistration);

    form_data.append('phone_one', this.state.phone_one);
    form_data.append('phone_two', this.state.phone_two);

    form_data.append('email', this.state.email);
    form_data.append('website', this.state.website);
    form_data.append('box_number', this.state.box_number);
    form_data.append('profession', this.state.profession);
    form_data.append('nationality', this.state.nationality);
    form_data.append('marital_status', this.state.marital_status);
    form_data.append('comments', this.state.comments);

    axios.put(serverconfig.backendserverurl+`/api/personal_parties/${person_ID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    this.component_reloaded()

    ///refesrh
    this.props.onRefresh()

  }    

  )
  .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
         <h4>Update Form</h4>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="First Name"
      
      >
        <Input name="first_name"  placeholder="first_name."
         value={this.state.first_name} 
         onChange={(val)=>{this.setState({first_name:val.target.value})}} />
      </FormItem>

      <FormItem label="Second Name"
        
      >
        <Input name="second_name"  placeholder="second_name."
         value={this.state.second_name} 
         onChange={(val)=>{this.setState({second_name:val.target.value})}} />
      </FormItem>


      <FormItem label={"Date of birth ("+this.state.date_of_birth+")"}
       
      >
          <DatePicker 
           onChange={(date, dateString)=>{this.setState({ date_of_birth: dateString})}}
           format={dateFormat} />
      </FormItem>



      <FormItem label="ID Number"
       
      >
        <Input name="id_number"  placeholder="id_number."
         value={this.state.id_number} 
         onChange={(val)=>{this.setState({id_number:val.target.value})}} />
      </FormItem>


      <FormItem label="Select Gender"
         
      
      >
          <Select 
          placeholder="Gender" 
          style={{  }} 
          value={this.state.gender}
          onChange={(val)=>{
            this.setState({gender:val})
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}                
          >
          <Option value={"Male"}>Male</Option>
          <Option value={"Female"}>Female</Option>
          </Select>
      </FormItem>


      <FormItem label="Select Nationality"
       
      
      >
          <Select 
          placeholder="Nationality" 
          style={{  }} 
          value={this.state.nationality}
          onChange={(val)=>{
            this.setState({nationality:val})
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}                
          >
            {
              this.state.countries.map((item)=>{
                return (<Option value={item.id}>{item.name}</Option>)
              })
            }
          </Select>
      </FormItem>


      <FormItem label="Marital Status"
      
      >
          <Select 
          placeholder="marital_status" 
          style={{  }} 
          value={this.state.marital_status}
          onChange={(val)=>{
            this.setState({marital_status:val})
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}                
          >
          <Option value={"Married"}>Married</Option>
          <Option value={"Single"}>Single</Option>
          <Option value={"Engaged"}>Engaged</Option>

          </Select>
      </FormItem>


      <FormItem label="Profession"
     
      >
        <Input name="profession"  
         placeholder="profession."
         value={this.state.profession} 
         onChange={(val)=>{this.setState({profession:val.target.value})}} />
      </FormItem>


       <Divider></Divider>   
       <h4>Contact section</h4>
      <FormItem label="Phone One"
        
      >
        <Input name="phone_one"  
         placeholder="phone_one."
         value={this.state.phone_one} 
         onChange={(val)=>{this.setState({phone_one:val.target.value})}} />
      </FormItem>


      <FormItem label="Phone Two"
       
      >
        <Input name="phone_two"  
         placeholder="phone_two."
         value={this.state.phone_two} 
         onChange={(val)=>{this.setState({phone_two:val.target.value})}} />
      </FormItem>

      <FormItem label="Email"
       
      >
        <Input name="email"  
         placeholder="email."
         value={this.state.email} 
         onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem>


      <FormItem label="Website"
       
      >
        <Input name="website"  
         placeholder="website."
         value={this.state.website} 
         onChange={(val)=>{this.setState({website:val.target.value})}} />
      </FormItem>

      <FormItem label="Box number"
        
      >
        <Input name="website"  
         placeholder="box_number."
         value={this.state.box_number} 
         onChange={(val)=>{this.setState({box_number:val.target.value})}} />
      </FormItem>
     

      <Divider></Divider>   

      <FormItem label={"Date of registration ("+this.state.date_ofregistration+")"}
       
      >
          <DatePicker 
           onChange={(date, dateString)=>{this.setState({ date_ofregistration: dateString})}}
           format={dateFormat} />
      </FormItem>



      <FormItem label="Comments">
        <TextArea
            placeholder="comments"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.comments}
            onChange={(val)=>{this.setState({comments:val.target.value})}}
          />
        
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">Update Person</Button>
      </FormItem>

       </Form>
        </div>
      );
    }

  }

}


export default PersonUpdateForm;



