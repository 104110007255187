import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import LicencePeopleAttachmentForm from './LicencePeopleAttachmentForm';
import LicenceCompanyAttachmentForm from './LicenceCompanyAttachmentForm';


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;


var token= ''
var bizuserid= ''


class PartyCreation_Tabs extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    licence_obj: {},
    datarequested:true,

  };

  componentDidMount(){
    this.comp_reloaded()
    
}

comp_reloaded=()=>{

  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
 }else{
    bizuserid= ''
 }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  this.setState({datarequested:false})


}


  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>

          <Tabs
            defaultActiveKey="4"
            onChange={() => {}}
            tabPosition={'top'}
            >

            <TabPane tab="People" key="4">

               <LicencePeopleAttachmentForm 
                button_command={"Add"}
                created_licence={this.props.licence_id}
                on__finish={()=>{}}
                licence_id={this.props.licence_id}
                />

            </TabPane>


            <TabPane tab="Companies" key="6">
               <LicenceCompanyAttachmentForm 
                button_command={"Add"}
                created_licence={this.props.licence_id}
                on__finish={()=>{}}
                licence_id={this.props.licence_id}
                />

            </TabPane>




            
             </Tabs>

        </div>
    )

    }

   
  }
}

export default PartyCreation_Tabs; 
