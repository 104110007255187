import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Spin } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class LicenceDetails extends React.Component{

    state={
        datarequested:true,
    }


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }
      this.setState({datarequested:false})

    }

    

    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>            </div>
        )
  
      }else{
        return(
          <div>
            <Card>

              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
              
              {/**Code */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Code:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.code}</span>
              </span>
              

              {/**Jurisdiction */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Jurisdiction:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.jurisidiction_name}</span>
              </span>

              {/**Application date */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Application date:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.application_date}</span>
              </span>

                {/**Name */}
                <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Name:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.name}</span>
              </span>

              {/**Type */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Type:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.mineral_licence_category_name}</span>
              </span>


              {/**Grant date */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Grant Date:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.grant_date}</span>
              </span>

                {/**Grant date */}
                <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Last renewal date:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.last_renewal_date}</span>
              </span>

              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Renewal period:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.renewal_period}</span>
              </span>

              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Status:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.status_name}</span>
              </span>

              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Status:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.status_name}</span>
              </span>


              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Application No:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.props.licence_record.application_no}</span>
              </span>

              

              </div>

            </Card>
          </div>
      )
      }
       
    }
}

export default LicenceDetails; 