import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;



const { Option } = Select;

class CompanyTypeForm extends React.Component {

  state = {
    name:'' ,  
    datarequested:true,
    period:'',
    description:''

  }

  componentDidMount(){   
    this.component_reloaded()
  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})


  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('description', this.state.description);

    axios.post(serverconfig.backendserverurl+'/api/company_types/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    this.component_reloaded()

    ///refesrh
    this.props.onRefresh()

  }    

  )
  .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name"  placeholder="name." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>


      <FormItem label="description">
        <TextArea
            placeholder="description"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.description}
            onChange={(val)=>{this.setState({description:val.target.value})}}
          />
        
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">Create Type</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default CompanyTypeForm;



