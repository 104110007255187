import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;



const { Option } = Select;

class CommodityUpdateForm extends React.Component {

  state = {
    name:'' ,  
    datarequested:true,
    period:'',
    description:'',
    licence_commodity_groups:[],
    commodity_group:''


  }

  componentDidMount(){   
    this.component_reloaded()
  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      username=''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/licence_commodity_groups/`)
    .then(res => {
        this.setState({
            licence_commodity_groups:res.data
        })
        this.setState({datarequested:false})
    })


    const comm_ID=this.props.comm_ID;
    axios.get(`${serverconfig.backendserverurl}/api/licence_commodities/${comm_ID}`)
    .then(res => {  

        this.setState({name:res.data.name})
        this.setState({description:res.data.description})
        this.setState({commodity_group:res.data.commodity_group})

        this.setState({datarequested:false})
    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const comm_ID=this.props.comm_ID;

    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('description', this.state.description);
    form_data.append('commodity_group', this.state.commodity_group);

    axios.put(serverconfig.backendserverurl+`/api/licence_commodities/${comm_ID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    this.component_reloaded()

    ///refesrh
    this.props.onRefresh()

  }    

  )
  .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="name">
        <Input name="name"  placeholder="name." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>


      <FormItem label="Group">
          <Select 
          placeholder="Group" 
          style={{  }} 
          value={this.state.commodity_group}
          onChange={(val)=>{
            this.setState({commodity_group:val})
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}   
          >
            {this.state.licence_commodity_groups.map(
              (type)=>(
                <Option value={type.id}>{type.name}</Option>
              ))}
          </Select>
      </FormItem>


      <FormItem label="description">
        <TextArea
            placeholder="description"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.description}
            onChange={(val)=>{this.setState({description:val.target.value})}}
          />
        
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default CommodityUpdateForm;



