import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import MineralLicenceCategoriesList from './MineralLicenceCategoriesList';
import LicenceStatus from './LicenceStatus';
import LicencesList from './LicencesList';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;


var token= ''
var bizuserid= ''


class LicencesTab extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    jurisdictions: [],
    datarequested:true,

  };

  componentDidMount(){
    this.comp_reloaded()
    
}

comp_reloaded=()=>{

  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
 }else{
    bizuserid= ''
 }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/api/jurisdictions/`)
  .then(res => {
      this.setState({
          jurisdictions:res.data
      })

      this.setState({datarequested:false})


  })



}




  render() {
    

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>

          <Tabs
            defaultActiveKey="1"
            onChange={() => {}}
            tabPosition={'top'}
          >
             <TabPane tab="Licences " key="1">
              <LicencesList />

             </TabPane>

             <TabPane tab="licence Categories" key="2">
              <MineralLicenceCategoriesList />

             </TabPane>

             <TabPane tab="licence Status" key="3">
                <LicenceStatus />
             </TabPane>

             </Tabs>

        </div>
    )

    }

   
  }
}

export default LicencesTab; 
