import React from 'react';
import { Form, Input, Button,Spin,Select,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import AddressForm from './AddressForm';
import LicenceCommoditiesForm from './LicenceCommoditiesForm';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;


const { Option } = Select;

class LicenceForm extends React.Component {

  state = {
    name:'' ,  
    datarequested:true,
   
    mineral_licence_categories:[],
    jurisdictions:[],
    jurisidiction:'',
    mineral_licence_category:'',
    status:'',
    licence_statuses:[],
    application_date:'',
    grant_date:'',
    last_renewal_date:'',
    expiry_date:'',
    application_no:0,

    comment:'',
    renewal_period:0,
    accounts:[],
    responsible_officer:'',
    created_licence:'',
    next_wizard_form:1

  }

  componentDidMount(){   
    this.component_reloaded()
  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   }else{
      username=''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/mineral_licence_categories/`)
    .then(res => {
        this.setState({
            mineral_licence_categories:res.data
        })
  
       // this.setState({datarequested:false})
  
  
    })

    axios.get(serverconfig.backendserverurl+`/api/jurisdictions/`)
    .then(res => {
        this.setState({
          jurisdictions:res.data
        })
          this.setState({datarequested:false})
      })

      axios.get(serverconfig.backendserverurl+`/api/licence_statuses/`)
      .then(res => {
          this.setState({
            licence_statuses:res.data
          })
      })

      axios.get(serverconfig.backendserverurl+`/api/accounts/`)
      .then(res => {
          this.setState({
            accounts:res.data
          })
      })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('jurisidiction', this.state.jurisidiction);
    form_data.append('mineral_licence_category', this.state.mineral_licence_category);
    form_data.append('status', this.state.status);
    form_data.append('application_date', this.state.application_date);
    form_data.append('grant_date', this.state.grant_date);
    form_data.append('last_renewal_date', this.state.last_renewal_date);
    form_data.append('expiry_date', this.state.expiry_date);
    form_data.append('application_no', this.state.application_no);
    form_data.append('comment', this.state.comment);
    form_data.append('renewal_period', this.state.renewal_period);
    form_data.append('responsible_officer', this.state.responsible_officer);
    form_data.append('username', username);

    axios.post(serverconfig.backendserverurl+'/api/licences/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})

    this.setState({created_licence:res.data.new_object_id})

    //set next form command
    this.setState({next_wizard_form:1})


      //this.component_reloaded()

      ///refesrh
      //this.props.onRefresh()

  }    

  )
  .catch(error => console.log(error))

  }

  //set next form
  setnext_form=() =>{
    //set next form command
    this.setState({next_wizard_form:2})
  }


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (  
        <div>
          {/**Form one */}
          {
            this.state.created_licence===""?
            <Form  onFinish={(event) => this.handleFormSubmit(event)}>
        
          {/**Next button */}
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
           <h4 style={{display:'flex',justifyContent:'center',color:'green'}}>Licence Details Form</h4>
      
           <FormItem>
          <Button  type="primary" htmlType="submit">Next </Button>
          </FormItem>
          </div>  
          <Divider></Divider>


          {/**Section one of the form */}
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
            {/**Left panel */}
            <div style={{display:'flex',width:'50%',flexDirection:'column',padding:10}}>
          
            <FormItem label="Jurisdiction"
                name="Jurisdiction"
                rules={[
                  {
                    required: true,
                    message: 'Please select',
                  },
                ]}
              >
                  <Select 
                  placeholder="Jurisdiction" 
                  style={{  }} 
                  value={this.state.jurisidiction}
                  onChange={(val)=>{
                    this.setState({jurisidiction:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}   
                  >
                    {this.state.jurisdictions.map(
                      (itm)=>(
                        <Option value={itm.id}>{itm.name}</Option>
                      ))}
                  </Select>
              </FormItem>


              <FormItem label="Licence Type"
                name="mineral_licence_category"
                rules={[
                  {
                    required: true,
                    message: 'Please select',
                  },
                ]}
              >
                  <Select 
                  placeholder="mineral_licence_category" 
                  style={{  }} 
                  value={this.state.mineral_licence_category}
                  onChange={(val)=>{
                    this.setState({mineral_licence_category:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}   
                  >
                    {this.state.mineral_licence_categories.map(
                      (itm)=>(
                        <Option value={itm.id}>{itm.licence}</Option>
                      ))}
                  </Select>
              </FormItem>



            <FormItem label="Status"
              name="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select',
                },
              ]}
            >
                <Select 
                placeholder="Status" 
                style={{  }} 
                value={this.state.status}
                onChange={(val)=>{
                  this.setState({status:val})
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}   
                >
                  {this.state.licence_statuses.map(
                    (itm)=>(
                      <Option value={itm.id}>{itm.name}</Option>
                    ))}
                </Select>
            </FormItem>


           
            <FormItem label="Licence Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name',
              },
            ]}
          >
            <Input
            name="name"  
            placeholder="name."
            value={this.state.name} 
            onChange={(val)=>{this.setState({name:val.target.value})}} />
          </FormItem>

          <FormItem label={"Application  Date"}
              name='application_date'
              rules={[
                {
                  required: true,
                  message: 'Please Select date',
                },
              ]}
            >
            <DatePicker 
              onChange={(date, dateString)=>{this.setState({ application_date: dateString})}}
              format={dateFormat} />
         </FormItem>

         <FormItem label={"Grant  Date"}
              name='grant_date'
              rules={[
                {
                  required: true,
                  message: 'Please Select date',
                },
              ]}
            >
            <DatePicker 
              onChange={(date, dateString)=>{this.setState({ grant_date: dateString})}}
              format={dateFormat} />
         </FormItem>



            </div>



            {/**Right panel */}
            <div style={{display:'flex',width:'50%',flexDirection:'column',padding:10}}>
            <FormItem label={"Last Renewal Date"}
              name='last_renewal_date'
              rules={[
                {
                  required: true,
                  message: 'Please Select date',
                },
              ]}
            >
              <DatePicker 
                onChange={(date, dateString)=>{this.setState({ last_renewal_date: dateString})}}
                format={dateFormat} />
          </FormItem>


          <FormItem label={"Expiry Date"}
                name='expiry_date'
                rules={[
                  {
                    required: true,
                    message: 'Please Select date',
                  },
                ]}
              >
              <DatePicker 
                onChange={(date, dateString)=>{this.setState({ expiry_date: dateString})}}
                format={dateFormat} />
          </FormItem>
            
            
            
            <FormItem label="Application No"
              name="application_no"
              rules={[
                {
                  required: true,
                  message: 'Please input Number',
                },
              ]}
            >
              <Input name="application_no" 
                placeholder="name." 
                value={this.state.application_no} 
                onChange={(val)=>{this.setState({application_no:val.target.value})}}
                type='number'
                />
            </FormItem>


            <FormItem label="Renewal Period"
              name="renewal_period"
              rules={[
                {
                  required: true,
                  message: 'Please input Number',
                },
              ]}
            >
              <Input 
                name="renewal_period" 
                placeholder="name." 
                value={this.state.renewal_period} 
                onChange={(val)=>{this.setState({renewal_period:val.target.value})}}
                type='number'
                />
            </FormItem>

            <FormItem label="Responsible Officer"
              name="Officer"
              rules={[
                {
                  required: true,
                  message: 'Please select',
                },
              ]}
            >
                <Select 
                placeholder="Officer" 
                style={{  }} 
                value={this.state.responsible_officer}
                onChange={(val)=>{
                  this.setState({responsible_officer:val})
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}   
                >
                  {this.state.accounts.map(
                    (itm)=>(
                      <Option value={itm.id}>{itm.username}</Option>
                    ))}
                </Select>
            </FormItem>



            <FormItem label="Comment">
              <TextArea
                  placeholder="Comment"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  value={this.state.comment}
                  onChange={(val)=>{this.setState({comment:val.target.value})}}
                />
              
            </FormItem>

           


            </div>

          </div>

          <Divider></Divider>


           </Form>
            :
            <div>
              {/**Display Address form */}

              {
                this.state.next_wizard_form===1 && this.state.created_licence!=""?
                <AddressForm 
                  button_command={"Next"}
                  created_licence={this.state.created_licence}
                  on__finish={this.setnext_form}
                  licence_id={this.state.created_licence}

                  />
                :
                null
              } 





            </div>

          }

        </div>
        
      );
    }

  }

}


export default LicenceForm;



