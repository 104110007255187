import React from 'react';
import { Form, Input, Button,Spin,Select,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;


const { Option } = Select;

class CompanyForm extends React.Component {

  state = {
    name:'' ,  
    datarequested:true,
    period:'',
    comments:'',
    abbreviation:'',
    id_number:'',
    gender:'',
    date_of_birth:'',
    date_ofregistration:'',
    phone_one:'',
    phone_two:'',

    box_number:'',
    website:'',
    email:'',
    profession:'',
    company_type:'',
    company_types:[],
    marital_status:''

  }

  componentDidMount(){   
    this.component_reloaded()
  }


  component_reloaded=()=>{
    this.setState({datarequested:true})

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   }else{
      username=''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/company_types/`)
    .then(res => {
        this.setState({
            company_types:res.data
        })
  
        this.setState({datarequested:false})
  
  
    })
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('abbreviation', this.state.abbreviation);
    form_data.append('date_ofregistration', this.state.date_ofregistration);

    form_data.append('phone_one', this.state.phone_one);
    form_data.append('phone_two', this.state.phone_two);

    form_data.append('email', this.state.email);
    form_data.append('website', this.state.website);
    form_data.append('box_number', this.state.box_number);
    form_data.append('company_type', this.state.company_type);
    form_data.append('comments', this.state.comments);

    axios.post(serverconfig.backendserverurl+'/api/companies/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    this.component_reloaded()

    ///refesrh
    this.props.onRefresh()

  }    

  )
  .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
      <h4>Creation Form</h4>

    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Company Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name"  placeholder="name."
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>

      <FormItem
        label="Abbreviation"
        name="abbreviation"
        rules={[
          {
            required: true,
            message: 'Please input abbreviation',
          },
        ]}
      >
        <Input name="abbreviation"  placeholder="abbreviation."
         value={this.state.abbreviation} 
         onChange={(val)=>{this.setState({abbreviation:val.target.value})}} />
      </FormItem>



      <FormItem label="Select Type"
         rules={[
          {
            required: false,
            message: 'Please select ',
          },
        ]}
      
      >
          <Select 
          placeholder="Company Type" 
          style={{  }} 
          value={this.state.company_type}
          onChange={(val)=>{
            this.setState({company_type:val})
          }} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}                
          >
            {
              this.state.company_types.map((item)=>{
                return (<Option value={item.id}>{item.name}</Option>)
              })
            }
          </Select>
      </FormItem>


       <Divider></Divider>   
       <h4>Contact section</h4>
      <FormItem label="Phone One"
        name="phone_one"
        rules={[
          {
            required: true,
            message: 'Please input phone_one',
          },
        ]}
      >
        <Input name="phone_one"  
         placeholder="phone_one."
         value={this.state.phone_one} 
         onChange={(val)=>{this.setState({phone_one:val.target.value})}} />
      </FormItem>


      <FormItem label="Phone Two"
        name="phone_two"
        rules={[
          {
            required: false,
            message: 'Please input phone_two',
          },
        ]}
      >
        <Input name="phone_two"  
         placeholder="phone_two."
         value={this.state.phone_two} 
         onChange={(val)=>{this.setState({phone_two:val.target.value})}} />
      </FormItem>

      <FormItem label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input email',
          },
        ]}
      >
        <Input name="email"  
         placeholder="email."
         value={this.state.email} 
         onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem>


      <FormItem label="Website"
        name="website"
        rules={[
          {
            required: false,
            message: 'Please input website',
          },
        ]}
      >
        <Input name="website"  
         placeholder="website."
         value={this.state.website} 
         onChange={(val)=>{this.setState({website:val.target.value})}} />
      </FormItem>

      <FormItem label="Box number"
        name="box_number"
        rules={[
          {
            required: false,
            message: 'Please input box_number',
          },
        ]}
      >
        <Input name="website"  
         placeholder="box_number."
         value={this.state.box_number} 
         onChange={(val)=>{this.setState({box_number:val.target.value})}} />
      </FormItem>
     

      <Divider></Divider>   

      <FormItem label={"Date of registration ("+this.state.date_ofregistration+")"}
        name='date_ofregistration'
        rules={[
          {
            required: true,
            message: 'Please Select date',
          },
        ]}
      >
          <DatePicker 
           onChange={(date, dateString)=>{this.setState({ date_ofregistration: dateString})}}
           format={dateFormat} />
      </FormItem>


      <FormItem label="Comments">
        <TextArea
            placeholder="comments"
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.state.comments}
            onChange={(val)=>{this.setState({comments:val.target.value})}}
          />
        
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">Add Company</Button>
      </FormItem>
      

       </Form>
        </div>
      );
    }

  }

}


export default CompanyForm;



