import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import LicenceForm from '../components/LicenceForm';
import Licence_DTabs from './Licence_DTabs';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;


var token= ''
var bizuserid= ''


class LicencesList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    licences: [],
    datarequested:true,

  };

  componentDidMount(){
    this.comp_reloaded()
    
}

comp_reloaded=()=>{

  if(localStorage.getItem("token")){
     token= localStorage.getItem("token")
  }else{
     token= ''
  }

  if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
 }else{
    bizuserid= ''
 }

  axios.defaults.headers={
    "Content-Type":"application/json",
    Authorization:`Token ${token}`
  }

  axios.get(serverconfig.backendserverurl+`/api/licences/`)
  .then(res => {
      this.setState({
          licences:res.data
      })
      this.setState({datarequested:false})

  })



}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {

    const columns = [
      {
        title: 'Details',
        dataIndex: 'id',
        key: 'id',
        render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/licence_details/${text}`}>
           <FundViewOutlined style={{color:'blue'}}/>
           </Link>
          </Popover>
          </p>

      },
    
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'Licence Type',
        dataIndex: 'mineral_licence_category_name',
        key: 'id',
      },
      {
        title: 'ApplicationDate',
        dataIndex: 'application_date',
        key: 'id',
      },
      {
        title: 'GrantDate',
        dataIndex: 'grant_date',
        key: 'id',
      },
      {
        title: 'Last RenewalDate',
        dataIndex: 'last_renewal_date',
        key: 'id',
      },
      {
        title: 'Renewal Period',
        dataIndex: 'renewal_period',
        key: 'id',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'id',
      },


      
      
    ];
    

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>      
         </div>
      )

    }else{
      return(
        <div>

          <Tabs
            defaultActiveKey="2"
            onChange={() => {}}
            tabPosition={'top'}
          >
             <TabPane tab={"List ( "+this.state.licences.length+" )"} key="2">
                <Table 
                columns={columns} 
                scroll={{ x: 1000 }}
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.licences}
                bordered
                />
              </TabPane>

              <TabPane tab="New Licence Wizard (NLW)" key="3">
                <LicenceForm />
              </TabPane>
            

             </Tabs>

        </div>
    )

    }

   
  }
}

export default LicencesList; 
